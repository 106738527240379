import { Flex, NakedButton, BackgroundImage, Container, Box } from '@qga/roo-ui/components';
import styled from '@emotion/styled';
import { themeGet } from 'styled-system';
import { rem } from 'polished';
import { mediaQuery } from 'lib/styledSystem';
import { TabPanels as ReachTabPanels } from '@reach/tabs';
import isPropValid from '@emotion/is-prop-valid';

export const HeroImage = styled(BackgroundImage)`
  position: absolute;
  top: 0;
  width: 100%;

  ${mediaQuery.minWidth.md} {
    position: static;
    height: ${rem('625px')};
  }
`;

export const TabsWrapper = styled(Container)`
  position: relative;
  bottom: 0;

  ${mediaQuery.minWidth.md} {
    position: absolute;
    max-width: ${themeGet('maxWidths.default')};
    top: ${rem('165px')};
    left: 0;
    right: 0;
    margin-left: auto;
    margin-right: auto;
  }
`;

export const SearchWrapper = styled(Flex)`
  background-color: ${themeGet('colors.white')};
  border-radius: 0 0 ${themeGet('radii.default')} ${themeGet('radii.default')};
  padding: ${themeGet('space.8')} ${themeGet('space.3')};
  min-height: ${rem('320px')};
  margin-bottom: 2px;

  ${mediaQuery.minWidth.sm} {
    min-height: ${rem('320px')};
  }

  ${mediaQuery.minWidth.md} {
    border-radius: 0 ${themeGet('radii.exaggerated')} ${themeGet('radii.exaggerated')} ${themeGet('radii.exaggerated')};
    padding: ${themeGet('space.8')} ${themeGet('space.10')} ${themeGet('space.10')};
    min-height: ${(props) => (props.shrink ? rem('260px') : rem('320px'))};
  }
`;

export const ResponsiveTabs = styled(Flex)`
  background-color: transparent;
  border-radius: ${themeGet('radii.default')} ${themeGet('radii.default')} 0 0;
  padding-top: 0;

  ${mediaQuery.minWidth.md} {
    background-color: transparent;
    padding: 0;
  }
`;

export const TabButton = styled(NakedButton)`
  display: inline-flex;
  justify-content: center;
  align-items: center;
  background-color: ${themeGet('colors.greys.porcelain')};
  color: ${themeGet('colors.greys.charcoal')};
  height: ${rem('53px')};
  width: 50%;
  opacity: 0.9;
  border-radius: ${(props) => (props.isLeftTab ? '4px 0 0 0' : props.isMiddleTab ? '0 0 0 0' : '0 4px 0 0')};
  border-left: ${(props) => (props.LeftTab ? 'none' : '1px solid white')};
  border-right: ${(props) => (props.isMiddleTab ? '1px solid white' : 'none')};

  &[data-selected] {
    background-color: ${themeGet('colors.white')};
    border-bottom: none;
    opacity: 1;
  }

  &:focus {
    outline: none;
  }

  @media (hover: hover) {
    &:hover {
      background-color: ${themeGet('colors.white')};
      opacity: 1;
    }
  }

  ${mediaQuery.minWidth.sm} {
    width: ${rem('173px')};
  }
`;

export const TabPanels = styled(ReachTabPanels)`
  width: 100%;
`;

type LabelContainer = {
  isActive: boolean;
};

export const LabelContainer = styled(Box, { shouldForwardProp: isPropValid })<LabelContainer>`
  border-bottom: 3px solid;
  border-bottom-color: ${(props) => (props.isActive ? themeGet('colors.brand.primary') : 'transparent')};

  @media (hover: hover) {
    ${TabButton}:hover & {
      width: 113px;
      border-bottom: 3px solid;
      border-bottom-color: ${themeGet('colors.brand.primary')};
    }
  }

  ${TabButton}:focus-visible & {
    width: 113px;
    background-color: ${themeGet('colors.white')};
    border-bottom: 3px solid;
    border-bottom-color: ${themeGet('colors.brand.primary')};
  }
`;
