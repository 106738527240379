import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { tabClick } from 'store/search/searchActions';

const useTabClickEvent = () => {
  const dispatch = useDispatch();

  const tabClickEvent = useCallback(
    ({ itemText, itemName, pageType, tabIndex, variant }) => {
      dispatch(
        tabClick({
          itemText: itemText,
          itemName: itemName,
          pageType: pageType,
          tabIndex: tabIndex,
          variant: variant,
        }),
      );
    },
    [dispatch],
  );

  return { tabClickEvent };
};

export default useTabClickEvent;
